import React from 'react';

const TermsPolicies = () => {
    return (
        <div className="container">
            <div className="content-page-area">
                <h2>Terms & Conditions</h2>
                <p>
                    These terms apply to all customers that receive services from Revorium. If you
                    have been directed to this page from within our product, these terms also apply
                    to your account regardless of the country in which your business or freelancing
                    activity is registered. If you have any questions regarding which Revorium
                    entity you receive services from, please reach out to customer service from the
                    web app.
                </p>
                <strong>- Why this information is important</strong>
                <p>
                    This document sets out the terms and conditions for your Revorium account (the
                    account) and its related services. It also sets out other important things that
                    you need to know. These terms and conditions, along with our privacy policy and
                    any other terms and conditions that apply to our services, form a legal
                    agreement (the agreement) between. You, the account holder; and us, Revorium. We
                    are an Estonian Information and Communications Technology company. Our company
                    Revorium OÜ; registration number 16847017. It's important for you to understand
                    how your account works. You can ask for a copy of these terms and conditions
                    through the Revorium dashboard at any time. It's important for you to understand
                    how your account works, so if you'd like more information you might find it
                    helpful to read our FAQs. The FAQs are for information only. They don't form
                    part of our agreement with you.
                </p>
                <strong>- What type of account is my Revorium account?</strong>
                <p>
                    Your Revorium account is a software platform which help you to create invoices
                    and sell your digital products and services to private and business clients and
                    get paid fast , easily and conveniently and that your earn from your clients by
                    providing / selling digital products and services throught Revorium as a
                    freelancer or remote worker . It shows your earnings in USD balance only for you
                    which you will be able to withdraw at anytime in your bank accounts and
                    different wallets in your local currency or in USD almost instantly.
                </p>
                <strong>- Using money in your Revorium account</strong>
                <p>
                    Once you have received the payment in your account you'll be able to use our
                    services. For example, you can do the following:
                    <label>- Sell your digital products and services globally</label>
                    <label>- Withdraw money to and receive money from your clients</label>
                    <label>- Make payments and withdraw cash using your Revorium Card</label>
                    <label>- View information about and manage your account and</label>
                    <label>
                        - Use Revorium’s API in your freelancing websites to sell services and get
                        paid easily and fast.
                    </label>
                </p>
                <p>
                    We add new features and services all the time. We'll let you know about these
                    through the Revorium Dashboard. You can access the Revorium Dashboard through
                    the account portal on our website.
                </p>
                <strong>- Can I open a Revorium account?</strong>
                <p>
                    You must be over 18 and a freelancer or a remote worker to open a Revorium
                    account. When you ask to open your account, the following applies:
                    <label>
                        - You promise that you have full authority to enter into the agreement and
                        meet the obligations under it and
                    </label>
                    <label>
                        - You are a freelancer, or remote worker, selling digital products or
                        services.
                    </label>
                    <label>
                        - We, or someone acting for us, will ask for certain information about you,
                        where the amount you are opening the account with comes from, and your
                        authorized persons (as explained below), We'll also ask you to confirm that
                        any authorized person has your authority to act on your behalf and that they
                        have agreed to keep to these terms and conditions.
                    </label>
                </p>
                <p>
                    We will only give you access to your Revorium account and the Revorium Dashboard
                    once we have all the information we need. You can't open more than one Revorium
                    account, or open a new Revorium account if you've previously closed a Revorium
                    account that you held. You also cannot open an account with us if you are a
                    charity, political organization, or religious organization.
                </p>
                <p>
                    You cannot open or hold a Revorium account if you carry out any sort of business
                    or activity that relates to the following:
                    <label>- Dating and escort services</label>
                    <label>- Pornography</label>
                    <label>- Weapons</label>
                    <label>- Trading in precious metals, stones, or art</label>
                    <label>- Running an auction house</label>
                    <label>- Chemicals and related products</label>
                    <label>- Video-game arcades</label>
                    <label>- Selling second-hand cars</label>
                    <label>- Binary options or gambling</label>
                    <label>- Debt collection or</label>
                    <label>
                        - Trading in prime-bank guarantees, debentures, letters of credit, or
                        medium-term notes.
                    </label>
                </p>
                <p>
                    We can refuse to let you open or hold a Revorium account if you carry out any
                    other business, freelancing, or activity that we are not comfortable with. Or we
                    may apply restrictions, which we'll tell you about before we allow you to open
                    the account
                </p>
                <strong>- How do I get information on payments into and out of my account?</strong>
                <p>
                    You can check all payments into and out of your account through the Revorium
                    dashboard. We will not make any changes to the information and it will be
                    available to you through the dashboard until you close your account. If you need
                    the information after then, you will need to download it while your account is
                    still active. You can download information from the dashboard at any time. We
                    will send a notification to your mobile or tablet, or by email, each time a
                    payment goes into or out of your account. You can turn off these notifications.
                    If you do this you should regularly check the Revorium dashboard for information
                    on payments. It's important that you know what payments are going into and out
                    of your account, so we recommend that you do not turn off notifications.
                </p>
                <p>
                    We'll usually communicate with you through the Revorium Dashboard. This is how
                    we will provide account information and tell you about any fraud, or suspected
                    fraud, relating to your account. It is also how we will tell you if there is a
                    security threat to your account. Make sure you regularly check the Revorium
                    Dashboard for this information. To help keep your account safe, download the
                    latest software and version of the Revorium Dashboard as soon as it is
                    available. We may also communicate with you by text message or email, so you
                    should regularly check your text messages and email account. We'll usually
                    communicate with you in English.
                </p>
                <p>
                    Keep us in the loop. Please keep your details up to date and let us know
                    immediately if any information you've given us changes. If we discover that any
                    of your information is incorrect we will update it. To meet our legal and
                    regulatory requirements we, or someone acting for us, might sometimes need to
                    ask for more information. Please provide this information quickly so that there
                    is no disruption to your account or our services.
                </p>
                <strong>- Revorium Dashboard</strong>
                <p>
                    You manage your account, portfolio and Revorium Cards through the
                    RevoriumDashboard, to which only you and the appropriately authorized persons
                    will have access.
                </p>
                <strong>- Revorium Dashboard</strong>
                <p>
                    You manage your account, portfolio and Revorium Cards through the
                    RevoriumDashboard, to which only you and the appropriately authorized persons
                    will have access.
                </p>
                <strong>- Delegating access to your account and the Revorium Dashboard</strong>
                <p>
                    The agreement is binding on you, but you can authorize other people (delegates)
                    to carry out certain activities on your behalf. There are three different
                    categories of delegates, all referred to in this document as authorized persons.
                </p>

                <strong>RESPONSIBILITY FOR AUTHORISED PERSONS</strong>
                <p>
                    We will treat all instructions and actions by authorized persons acting within
                    the limits of their authority as if you had given that instruction or carried
                    out that action yourself. It is your responsibility to withdraw your authority
                    from, or impose limits on, any authorized person (for example, if they are no
                    longer employed by you). Any authorized person must be 18 or over. You are also
                    responsible for all their activities. For example, if they lose their Revorium
                    Card or their security details, it is your responsibility to tell us in line
                    with these terms and conditions, although you may ask them to tell us instead.
                </p>
                <strong>Your Revorium Card</strong>
                <p>
                    Where these terms and conditions refer to your Revorium Card this includes all
                    Revorium Cards we issue to you and authorized persons.
                </p>
                <strong>- Open API</strong>
                <p>
                    Revorium’s API to increase your sales by providing services like automated
                    invoicing, and generation payment links within the invoice that allows you to
                    carry out certain activities such as setting up automatic payments out of your
                    account and displaying your account balance. If you ask us to, and your plan
                    allows it, we will give you an API Key that will allow you to use the Open API.
                </p>
                <strong>- What happens after my account is closed?</strong>
                <p>
                    We'll hold back enough money to cover any payments that you approved before your
                    account was closed. You'll also still owe us any money that you owed us while
                    your account was open.
                </p>
                <strong>- How do I get access to my money after my account has closed?</strong>
                <p>
                    For six years after your account has closed or your Revorium Card has expired,
                    you'll be able to contact customer services (at help@revorium.com) and ask them
                    to send you the money we still hold for you. Once your account is closed you can
                    only withdraw your money in the currency of the country you live in.
                </p>
                <strong>- How is my money protected?</strong>
                <p>
                    We don't lend your money to others. When we receive payment for your account
                    from your clients or buyers, or you add money to it through card, we place the
                    equivalent value of e-money in your account to show you and allow you to
                    withdraw at any time, at any amount. We quickly either:
                    <label>
                        - place the money into our ring-fenced accounts that we hold with large
                        global banks (ring-fenced accounts are separate from our own money) or
                    </label>
                    <label>
                        - invest the payment in low-risk assets held in a separate account with
                        financial institutions.
                    </label>
                </p>
                <p>
                    Safeguarding helps protect you if we were to become insolvent. Safeguarding
                    regulations make sure that once we have paid any costs related to an
                    administrator or liquidator, we must repay you from our ring-fenced accounts
                    before we repay any others we owe money to. Unfortunately, the law doesn't allow
                    us to pay you interest, and the money in your account isn't covered by the
                    Financial Services Compensation Scheme.
                </p>
                <strong>- Keeping your security details and Revorium Card safe</strong>
                <p>
                    We do everything we can to keep your money safe. We ask you and all authorized
                    persons to do the same by keeping your (and their) security details and Revorium
                    Card safe. Security details include usernames, API Keys (explained below), and
                    any passwords that allow access to the Revorium Dashboard. You shouldn't keep
                    your security details near your Revorium Card, and you should disguise or
                    protect them if you write them down or store them. Don't share your security
                    details with anyone other than an open-banking third-party provider who is
                    acting in line with regulatory requirements.
                </p>
                <p>
                    Please don't share your API Key with anyone. Sometimes it's easy to forget to
                    take the steps you and your authorized persons should take to keep your money
                    safe. Here are some tips
                    <label>
                        - make sure you close down the Revorium Dashboard when you're not using it
                    </label>
                    <label>
                        - don't give access to your API Key to anyone who shouldn't have access to
                        it
                    </label>
                    <label>
                        - don't give access to your API Key to anyone who shouldn't have access to
                        it
                    </label>
                    <label>- change your password or Revorium Card PIN regularly</label>
                    <label>
                        - Contact us through the Revorium dashboard if you lost your revorium card
                        or stolen it. Or if the card or the security details could be used without
                        your permission
                    </label>
                </p>
                <p>
                    If you can you should also freeze your Revorium Card using the Revorium
                    Dashboard or by calling the automated number below. If you later realize there's
                    not a risk to your Revorium Card's security, you can just unfreeze it
                </p>
                <p>
                    How you can contact us
                    <label>28th floor, Maakri 30, Tallinn 10145, Estonia</label>
                    <label>+3725736500</label>
                </p>
                <p>
                    Tell us about a lost or stolen Revorium Card or security details. Send us a
                    message through the Revorium Dashboard/app on someone else's device. Send us a
                    message on social media
                    <label>Email us at feedback@revorium.com</label>
                    <label>Call us +37253633471</label>
                </p>
                <strong>
                    - Are there any restrictions on using the Revorium Dashboard or Revorium Card?
                </strong>
                <p>
                    Please act reasonably and responsibly when using the Revorium Dashboard or
                    Revorium Card. The Revorium dashboard or Revorium card must not be used
                    (directly or indirectly) as follows:
                    <label>- for illegal purposes (for example, committing fraud</label>
                    <label>
                        - don't give access to your API Key to anyone who shouldn't have access to
                        it
                    </label>
                    <label>
                        - in a way that we reasonably believe might harm our ability to provide our
                        services
                    </label>
                    <label>- only to send money to and receive money from a credit card</label>
                    <label>
                        - for any transactions to receive cash (for example, getting cash back),
                        other than making a withdrawal from a cash machine
                    </label>
                    <label>- to control or use a Revorium account that's not yours</label>
                    <label>
                        - to give a Revorium Card to any person who is not an authorized person
                    </label>
                    <label>
                        - to allow anyone who isn't an authorized person to have access to or use
                        your account or the Revorium Dashboard
                    </label>
                    <label>
                        - to abuse, exploit or get around any usage restrictions set by a service
                        provider your Revorium Card is registered with. For example, you must only
                        use one Revorium Card for any particular service provider that offers a free
                        subscription or trial period
                    </label>
                    <label>
                        - to trade in foreign currencies for speculative purposes (that is, to take
                        advantage of any expected rise or fall in the value of a currency) or to
                        take advantage of discrepancies in the foreign exchange market; or
                    </label>
                    <label>
                        - to use our services to hold or carry out transactions with clients' money.
                    </label>
                    Please also act in a respectful way towards us and our support staff – we're
                    here to help you.
                </p>
                <strong>Payment limits</strong>
                <p>
                    Sometimes we might limit how much you can receive into or withdraw from your
                    account based on your subscription, or how much you can withdraw or spend using
                    your Revorium Card. We might also limit the value of currency exchange you can
                    carry out at any one time or over a period of time. We might be able to increase
                    the limit if you ask us to. These limits can change from time to time.
                    Information about these limits is set out in our FAQs
                </p>
                <strong>- Transferring money between Revorium accounts</strong>
                <p>
                    You can send money to other Revorium accounts. You may also be able to receive
                    money from other people's Revorium accounts. We call these sorts of payments
                    Instant Transfers. You can make an instant transfer to another Revorium user's
                    account by choosing them from the contacts list in the Revorium Dashboard and
                    following the prompts. The other person will receive the transfer immediately.
                </p>
                <strong>- Making other types of payments</strong>
                <p>
                    It’s easy to send/ withdraw money to your bank account from your earnings that
                    has been credited to your account.. You can make a one-off payment or set up a
                    recurring payment (like a standing order). Just enter the sort code and account
                    number (or, for international payments, the BIC and IBAN) of the account you're
                    sending/withdrawing the money to in the Revorium Dashboard and follow the
                    prompts.
                </p>
                <strong>Using your Revorium Car</strong>
                <p>
                    When you use your Revorium Card to make a withdrawal from a cash machine or make
                    a payment (for example, in a shop or restaurant), we will consider the payment
                    to be authorized by you unless:
                    <label>- You let us know that money has been stolen from your account.</label>
                    <label>- You don't think we've carried out your instructions correctly.</label>
                </p>
                <p>
                    Sometimes we might charge you a fee for making withdrawals. You will be able to
                    see the fees while withdrawing. We are not responsible for losses where payments
                    are returned in a different currency. Sometimes, the money you've asked us to
                    transfer to someone is not paid into their account and is returned to us. If we
                    had to carry out a currency exchange when we sent the payment and can show that
                    we did everything right, when we return the money to you we'll convert it back
                    to the original currency. This means that the amount you receive back into your
                    account might be less than the payment you made (or it could be more!). We would
                    not be responsible for any losses that this causes you
                </p>
                <p>
                    If the account you want to make the payment to is not a personal account (for
                    example, if it is a business or charity account), we might not carry out your
                    instruction. Take care entering the details of the person you want to pay, send
                    money or withdraw into your own account in different bank accounts or in
                    wallets.
                </p>
                <p>
                    When you enter the details of the person you want to pay and send or withdraw
                    money make sure the details are correct. If they're not, your payment might be
                    delayed or you might lose your money if you enter the wrong details. Make sure
                    you know the person you are making a payment to. If someone approaches you and
                    asks you to make a payment to them, but you are not sure who they are or what
                    the payment is for, you may be a victim of a scam and we may not be able to
                    recover your money for you. If the person you want to pay does not receive the
                    money, we won't be responsible if we processed the payment correctly but you
                    gave us the wrong details. If you ask us to we'll be happy to try to get your
                    money back, but this might be easier in some countries than in others and we
                    cannot guarantee that we will be able to.
                </p>
                <p>
                    If you reach out to our customer support team through the Revorium Dashboard, we
                    can provide you with information to help you file a claim to recover the money,
                    including the details of the person who did receive the money if we have them.
                </p>
                <strong>
                    - What happens if a payment was sent to the wrong account, wasn't sent at all,
                    or was delayed?
                </strong>
                <p>
                    We'll always try to process your payments correctly and on time, but sometimes
                    things go wrong and payment might be delayed or not received by the person you
                    wanted to pay or send. If something has gone wrong and
                    <label>- the person paying you</label>
                    <label>- the bank account you wanted to make the payment into or</label>
                    <label>- the retailer you were paying</label>
                    <label>
                        - is in the EEA, let us know through the Revorium Dashboard. You need to let
                        us know as soon as possible, and no later than 6 months after the amount was
                        taken from your account.
                    </label>
                </p>
                <p>
                    If money is not received in the account you sent it to, we'll refund the payment
                    back into your account. If you've had to pay any charges or interest as a result
                    of our mistake, we'll refund those too. If we received a payment on your behalf,
                    but the money was not paid into your account on time, we'll immediately credit
                    your account with the amount of the payment. These rules don't apply to currency
                    exchanges.
                </p>
                <p>
                    Instead, you must contact us within three months of the mistake. We won't be
                    responsible for the following:
                    <label>
                        any losses other than those that are due to us acting fraudulently or
                        negligently, or deliberately failing to do something or
                    </label>
                    <label>
                        any losses other than those that are directly caused by our failure, whether
                        or not those losses could reasonably have been expected.
                    </label>
                </p>
                <p>
                    If we received a payment on your behalf and the money has not been paid into
                    your account, or it was not paid into your account on time, we'll credit your
                    account with the amount of the payment within 10 business days.
                </p>
                <strong>- What exchange rate do you use?</strong>
                <p>
                    If you tell us to make a currency exchange, or we need to convert the currency
                    of a payment into or out of your account or a cash withdrawal made using your
                    Revorium Card, we'll use an exchange rate based on our market rate, which is
                    based on foreign-exchange markets. You can see the current rate in the Revorium
                    Dashboard. Once we've converted the currency, your transaction history in the
                    dashboard will show the exchange rate we used. We use the rate that applies at
                    the time we carry out the conversion.
                </p>
                <strong>We won't be responsible for the following:</strong>
                <p>
                    <label>- if you lose any money as a result of converting currency or</label>
                    <label>
                        - if you're charged any fees or lose any money because you're using your
                        Revorium Card in another country and you ask the retailer (or the retailer's
                        bank) to make the conversion. (For example, imagine you're a Bangladesh
                        customer traveling in Japan. When you pay your bill at a restaurant you
                        agree to pay in USD rather than yen. This means you've asked for the
                        retailer's bank to convert the currency. We can't be responsible if that
                        bank gives you a worse exchange rate or charges you fees)
                    </label>
                </p>
                <p>
                    If you tell us to make a currency exchange, or we need to convert the currency
                    of a payment into or out of your account or a cash withdrawal made using your
                    Revorium Card, we'll use an exchange rate based on our market rate, which is
                    based on foreign-exchange markets. We add a percentage (a markup).
                    <label>- for currencies that aren't always easily available</label>
                    <label>
                        - if you tell us to make a conversion outside foreign-exchange-market hours.
                        A conversion will be outside foreign-exchange-market hours if it's between
                        midnight on a Friday and midnight on a Sunday ( European time)
                    </label>
                </p>
                <strong>- Can I cancel a payment or currency exchange?</strong>
                <p>
                    You can cancel a payment/withdrawal at any time up to the end of the business
                    day before the payment is due to be paid from your account. You can't cancel
                    payment on the same day it's due to be paid from your account. This means that
                    you cannot cancel transfers between Revorium accounts. You also can't cancel a
                    currency exchange once we've received your request to carry it out. It's easy to
                    cancel a payment instruction. You can cancel an instruction to make a payment
                    through the Revorium Dashboard.
                </p>
                <strong>- How long does it take to make a payment?</strong>
                <p>
                    We understand that when you make a payment, one of the most important things is
                    that the person the payment is for receives it on time. When their bank will
                    receive the money depends on what time you tell us to make the payment, and the
                    currency you want us to make it in. The information below explains when we'll
                    make payments. Instant transfer to a Revorium account:
                </p>
                <strong>Payment to someone else's bank account or your own bank account:</strong>
                <p>
                    <label>
                        - If you provide your payment instruction before 1 pm on a business day,
                        we'll receive it immediately.
                    </label>
                    <label>
                        - If you provide your payment instruction after 1 pm on a day that is not a
                        business day, we'll receive it the next business day.
                    </label>
                </p>
                <p>
                    Payment to a bank account at a future date (such as a recurring payment or a
                    standing order:
                    <label>
                        - You can provide your payment instruction at any time and we'll receive it
                        the same business day (if the payment is due to come out of your account on
                        a business day) or the next business day (if the payment is due to come out
                        of your account on a non-business day).
                    </label>
                    <label>
                        - once we’ve taken the payment from your account, it will reach the account
                        of the person you are paying as soon as we can get the payment there. How
                        long it takes would depend on where the bank of the person you want to pay
                        is. Please contact us through the Revorium app and we’ll do what we can to
                        help you.
                    </label>
                    If you tell us to make a currency exchange you will receive the converted
                    e-money immediately.
                </p>
                <strong>When we will refuse or delay a payment</strong>
                <p>
                    We must refuse to make a payment, or delay a payment, in the following
                    circumstances:
                    <label>
                        - if legal or regulatory requirements prevent us from making the payment or
                        mean that we need to carry out further checks
                    </label>
                    <label>
                        - if you have broken these terms and conditions in a way that we reasonably
                        believe justifies us refusing or delaying your payment
                    </label>
                    <label>
                        - if we believe that processing your instruction would break these terms and
                        conditions or that your instruction doesn't contain all the information we
                        need to make the payment properly.
                    </label>
                    <label>
                        - if the amount is over, or would take you over, any limit that applies to
                        your account. We've set out the limits
                    </label>
                    <label>
                        - if there is not enough money available in your account to make the payment
                        and cover any charge.
                    </label>
                    <label>
                        - if you've been declared bankrupt or insolvent, are being wound up, or a
                        similar event is taking place.
                    </label>
                    <label>
                        - if, even after doing everything reasonably possible, we won't be able to
                        make the payment on time.
                    </label>
                    <label>
                        - if a third party prevents us from making the payment (for example, if
                        Mastercard or Visa do not allow payment or cash withdrawal using your
                        Revorium Card)
                    </label>
                    <label>
                        - if you owe us money or we intend to exercise our right of set-off
                    </label>
                    <label>
                        - if we have asked you for important information we reasonably need and you
                        have not given us that information or
                    </label>
                    <label>- if we have suspended your account.</label>
                </p>
                <strong>When we refuse to make a payment, we'll always try to let you know</strong>
                <p>
                    If we can, we'll use the Revorium Dashboard to tell you that we have refused to
                    make a payment. If you'd like to find out why we refused the payment, and what
                    you can do to solve any problem, please phone us on +37257836500. We won't be
                    responsible for any losses you suffer as a result of us refusing or delaying
                    payment or withdrawal.
                </p>
                <strong>- Fees for making or receiving payments</strong>
                <p>
                    We only charge fees for making or receiving payments where you’ve exceeded your
                    free allowance for that type of payment or if your allowance does not include
                    any free payments of that type. You can see what your free allowance is, and any
                    fees you’ll pay on top, on our Fees Pages. Where this is the case and you ask us
                    to make a payment, we’ll deduct the relevant fee from your account balance
                    separate to the amount you’ve asked us to send (when you are making a payment)
                    or deduct it from what you’re due to receive (when you are receiving a payment).
                </p>
                <p>
                    However, even if you haven’t exceeded your free allowance, other banks involved,
                    such as the bank of the person you are paying (or banks that help transfer the
                    money between other banks) might sometimes take their fees from the payment
                    you're sending or receiving. This could mean that you or the person you are
                    paying receives less than expected. For example, you could only receive $98 from
                    someone who has sent you $100 because the other person's bank has charged a $2
                    fee.
                </p>
                <p>
                    When you receive a payment, other banks involved charging a fee is out of our
                    control. However, when you send an eligible international payment with us, you
                    can avoid it from happening by using our Guaranteed SWIFT (OUR) Transfer
                    feature. This feature allows you to choose to pay the full fee yourself and
                    ensure that the recipient receives the full amount.
                </p>
                <p>
                    a fee is charged by us, you will be shown the fee in the app before you decide
                    to make the payment. What happens if something goes wrong.
                </p>
                <strong>- What happens if someone steals from my account?</strong>
                <p>
                    Let us know as soon as possible through the Revorium Dashboard (and no later
                    than within 6 months from the date the money was taken from your account). We'll
                    pay the money back into your account if all of the following apply:
                    <label>
                        - you couldn't have known that your security details or Revorium Card were
                        at risk of being misused.
                    </label>
                    <label>
                        - the payment happened because someone we're responsible for making a
                        mistake.
                    </label>
                    <label>
                        - the payment was taken after you told us that someone knew your security
                        details or your Revorium Card was lost or stolen, or we didn't give you a
                        way to tell us about this and
                    </label>
                    <label>
                        - the law required us to make you follow certain prompts when you instructed
                        us to make the payment and we didn't do this.
                    </label>
                </p>
                <p>
                    We'll also pay back any charges you had to pay as a result of the payment being
                    taken from your account. We won't refund any money if you've acted fraudulently,
                    or you intentionally or carelessly failed to keep your security details or
                    Revorium Card safe (unless you told us about this before the payment was taken
                    from your account). For example, we wouldn't make a refund if you gave someone
                    your Revorium Card PIN and they made a payment using your card without you
                    knowing about it.
                </p>
                <strong>
                    - When we might block access to your account, the API Key, or your Revorium Card
                </strong>
                <p>
                    The safety of your money is important to us. We might prevent you from making
                    payments from your account with the API Key or your Revorium Card if we're
                    reasonably concerned about its security or if it might be used fraudulently or
                    without your permission.
                </p>
                <p>
                    We might also have to block your account or your Revorium Card to meet our legal
                    obligations. We'll tell you through the Revorium Dashboard before, or as soon as
                    possible after, we block your account, API Key or Revorium Card. We'll also let
                    you know why we've done it (unless it would reduce your or our security or it
                    would be unlawful). We may also refuse to issue a new Revorium Card if you do
                    not have enough money in your account to pay us to issue or deliver the card.
                </p>
                <strong>- How you might owe us money</strong>
                <p>
                    You cannot borrow money from your account (for example, withdraw of more than
                    the value of the money in your total earnings ) If you owe us money, we can take
                    the amount you owe us from any amount we are due to pay to you. We call this our
                    right of set-off. If you owe us money and you don't add money to your account or
                    repay us within seven days, we can recover the amount by
                    <label>- taking the amount you owe us from your stored card</label>
                    <label>- exercising our right of set-off; or</label>
                    <label>
                        - taking other legal steps to recover the money you owe us, such as
                        instructing lawyers or debt collectors.
                    </label>
                    If we take any (or all) of these steps, we might charge you our reasonable
                    costs. You do not have any right to set off under this agreement.
                </p>
                <strong>- How to make a complaint</strong>
                <p>
                    If you're unhappy with our service, we'll try to put things right We always do
                    our best, but we realize that things sometimes go wrong. If you have a
                    complaint, please contact us. If you'd just like to speak to someone about an
                    issue that's concerning you, please contact us through the Revorium Dashboard.
                    We can usually settle matters quickly through the web app. You'll probably need
                    to give us the information below.
                </p>
                <p>
                    you can email us at formalcomplaints@revorium.com. You’ll need to tell us:
                    <label>- your name;</label>
                    <label>
                        - the phone number and email address associated with your account;
                    </label>
                    <label>- when the problem arose; and,</label>
                    <label>
                        - how you'd like us to put the matter right. We'll look into your complaint
                        and respond to you by email. We will communicate with you in English unless
                        we tell you otherwise.
                    </label>
                </p>
                <strong>- How long the agreement between you and us will last</strong>
                <p>Once the agreement has started it won't end until you or we end it.</p>

                <p>
                    <label>
                        <b>Cancellation</b>
                    </label>
                    If we offer you a free trial period and you decide that the account isn't right
                    for you, you can cancel the agreement for free at any time within the trial
                    period. Please contact us through the Revorium Dashboard if you would like to do
                    this. Automatic renewal and ending the agreement after a trial period. Unless
                    you cancel the agreement during the trial period, you can close your account,
                    and so end the agreement, at any time by letting us know. You should do this
                    through the Revorium Dashboard.
                </p>
                <p>
                    You will still have to pay any charges you've run up to that point. When you
                    tell us you want to close your account we will give you the opportunity to
                    withdraw the money we hold for you (we call this redemption). If you want us to
                    send you money in a different currency than the currency we're holding for you,
                    we will convert the currency using the rate that applies at the time, and take
                    our usual fee, before sending the money to you.
                </p>
                <p>
                    <label>
                        <b>Canceling your Revorium Card</b>
                    </label>
                    If you change your mind and don't want a Revorium Card anymore, that's not a
                    problem. Just let us know and we'll cancel it.
                </p>
                <strong>- When could you suspend or close my account?</strong>
                <p>
                    We may close or suspend your account immediately, and end your access to our
                    website, in exceptional circumstances. Exceptional circumstances include, for
                    example, the following:
                    <label>
                        - if we have good reason to suspect that you are behaving fraudulently or
                        otherwise criminally
                    </label>
                    <label>
                        - if you haven't given us (or someone acting on our behalf) any information
                        we need, or we have good reason to believe that information you have
                        provided is incorrect or not true
                    </label>
                    <label>
                        - if you've broken these terms and conditions in a serious or persistent way
                        (for example, if we discover that you're carrying out a business activity
                        that you are not allowed to carry out while you have a Revorium account)
                    </label>
                    <label>
                        - we've asked you to repay the money you owe us and you haven't done so
                        within a reasonable time
                    </label>
                    <label>
                        - we have good reason to believe that your use of the Revorium Dashboard and
                        Revorium account could damage our reputation or goodwill;
                    </label>
                    <label>
                        - we have good reason to believe that you've disposed of significant
                        business assets;
                    </label>
                    <label>
                        - we have good reason to believe that your use of the Revorium Dashboard is
                        harmful to us or our software, systems or hardware;
                    </label>
                    <label>
                        - you are a sole trader and you die or you are a partner in a business and
                        the partnership ends;
                    </label>
                    <label>
                        - there's been an important change in the type of business activities you
                        carry out;
                    </label>
                    <label>
                        - you've been declared bankrupt or insolvent, are being wound up, or a
                        similar event is taking place; or
                    </label>
                    <label>
                        - we have to do so under any law, regulation, court order or instructions
                        from financial authorities.
                    </label>
                </p>
                <p>
                    If we close your account in exceptional circumstances, you will only be able to
                    exchange funds into your base currency and send money via external bank transfer
                    before the account is closed. You will not be able to credit the account, make
                    card payments, withdraw money at an ATM, or send money to other Revorium
                    accounts. Any inbound payments will be rejected and returned to the sender.
                    Closing your Revorium account and ending your agreement with us may also end any
                    other agreements you have with us or through us with third parties.
                </p>
                <p>
                    If your account has been temporarily restricted, we may not be able to close
                    your account until we have completed our inquiries. Please speak to us through
                    the Revorium Dashboard or contact us for more information.
                </p>
                <strong>- We can change these terms</strong>
                <p>
                    Telling you about changes. If we add a new product or service that doesn't
                    change the terms and conditions of your account, we may add the product or
                    service immediately and let you know before you use the product or service.
                    Otherwise, we'll give you one month's notice through the Revorium Dashboard
                    before we make any changes. We'll assume you're happy with the change unless you
                    tell us that you want to close your account before the change is made.
                </p>
                <strong>
                    - Are you responsible if something goes wrong with my account, my Revorium Card,
                    or the Revorium Dashboard?
                </strong>
                <p>
                    We'll do as much as reasonably possible to make sure that our services are not
                    interrupted and are accessible at a reasonable speed. However, we can't
                    guarantee (except as required by law) that this will always be the case or that
                    the services will be free from faults. This is partly because we also rely on
                    some third parties to provide services to you. If you have a Revorium Card, we
                    will let you know about any changes to our system that will affect your ability
                    to use the card.
                </p>
                <p>
                    If you can't use your Revorium Card for any reason we will only be responsible
                    to you for replacing that card. We won't be responsible to you for any of the
                    following, whether direct or indirect, that arises in connection with these
                    terms and conditions:
                    <label>- loss of income or profit;</label>
                    <label>- loss of goodwill or damage to your reputation;</label>
                    <label>- loss of business contracts or opportunities;</label>
                    <label>- loss of anticipated savings; or</label>
                    <label>- consequential loss.</label>
                </p>
                <p>
                    Except when the law states otherwise, we also won't be liable for any loss you
                    suffer as a direct or indirect result of the following:
                    <label>
                        - the actions of any authorized person acting in line with these terms and
                        conditions and within any restrictions you have set
                    </label>
                    <label>- you giving us incorrect or incomplete information</label>
                    <label>- any delays in or disruptions to our services</label>
                    <label>
                        - any faults, mistakes or inaccuracies of any kind in our services;
                    </label>
                    <label>- information or services provided by third parties; or</label>
                    <label>- anything a third party does or fails to do.</label>
                </p>
                <p>
                    Nothing in these terms and conditions removes or limits:
                    <label>
                        - our liability for death or personal injury resulting from our negligence,
                        or from fraud or fraudulent claims or statements; or
                    </label>
                    <label>
                        - any other liability which, by law, cannot be removed or limited.
                    </label>
                </p>
                <p>
                    Losses arising as a result of legal or regulatory requirements, or unusual or
                    unexpected events. We will not be responsible for any losses you suffer or costs
                    you have to pay because of a legal or regulatory requirement, or because there
                    were unusual or unexpected events outside our control unless those losses or
                    costs resulting from us failing to meet our obligations to make payments into or
                    out of your account.
                </p>
                <p>
                    However, we will not be responsible for losses or costs relating to us failing
                    to make payments into or out of your account if this was because of events
                    beyond our control that we could not have avoided, even if we had taken all
                    reasonable steps to prevent them.
                </p>
                <strong>- When you might be responsible for our losses</strong>
                <p>
                    You may be responsible to us for certain losses. If you have broken these terms
                    and conditions, and this has caused us to suffer a loss, the following will
                    apply:
                    <label>
                        - you will be responsible for any losses we suffer as a result of your
                        action (we will try to keep the losses to a minimum);
                    </label>
                    <label>
                        - if your actions result in us losing profits, you may also be responsible
                        for those losses. You won't be responsible if this would mean that we are
                        compensated twice for the same loss, and
                    </label>
                    <label>
                        - you will also be responsible for any reasonable legal costs that arise as
                        a result of our losses.
                    </label>
                </p>
                <strong>- Permission for us to process your personal information</strong>
                <p>
                    To provide services under the agreement we will need to collect information
                    about you (and any authorized person). Under data protection law, we are what is
                    known as the 'data controller of your personal information. For more information
                    about how we use your personal information, see our Privacy Policy. By entering
                    into the agreement you are giving us permission (and the permission of any
                    authorized person) to gather, store and process personal information for the
                    purpose of providing our services to you. This doesn't affect any rights and
                    obligations you or we have under data protection law.
                </p>
                <p>
                    Our Privacy Policy sets out the lawful reasons for using your personal
                    information. You can withdraw your permission by closing your account, which
                    will end the agreement between you and us. If you do this, we'll stop using your
                    information for the purpose of providing our services, but we may need to keep
                    your information for other legal reasons.
                </p>
                <strong>- Our intellectual property</strong>
                <p>
                    We own all the intellectual property in our products (for example, the content
                    in the Revorium Dashboard and on our website, and our logo and card designs).
                    You must not use our intellectual property as your own, except to enjoy our
                    products and services. You also must not reverse-engineer any of our products
                    (that is, reproduce them after a detailed examination of their construction or
                    composition).
                </p>
                <strong>- Some legal bits and pieces</strong>
                <p>
                    <label>
                        <b>Our contract with you.</b>
                    </label>
                    Only you and we have any rights under the agreement. You may not transfer or
                    assign any of your rights or obligations under these terms and conditions.
                </p>
                <p>
                    <label>
                        <b>Estonian law applies</b>
                    </label>
                    The laws of Estonia and Tallinn apply to the agreement. The English version of
                    the agreement applies. If these terms and conditions are translated into another
                    language, the translation is for reference only and the English version will
                    apply.
                </p>
                <p>
                    <label>
                        <b>Our right to enforce the agreement</b>
                    </label>
                    If you have broken the agreement between you and us and we don't enforce our
                    rights, or we delay in enforcing them, this will not prevent us from enforcing
                    those or any other rights at a later date. Taking legal action against us. Any
                    legal action between you and us will be decided in the courts of Estonia and
                    Tallinn.
                </p>
            </div>
        </div>
    );
};

export default TermsPolicies;
