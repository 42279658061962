import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    // eslint-disable-next-line prettier/prettier
    AccordionItemPanel
} from 'react-accessible-accordion';
import './Faq.css';

import 'react-accessible-accordion/dist/fancy-example.css';

const Faq = () => {
    return (
        <div className="container">
            <div className="faq-page">
                <h2>Frequently asked questions</h2>
                <Accordion>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What kind of account is my Revorium account?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Your Revorium account is a software platform which help you to
                                create invoices and sell your digital products and services to
                                private and business clients and get paid fast , easily and
                                conveniently and holds your e-money that your earn from your clients
                                by providing / selling digital products and services throught
                                Revorium as a freelancer or remote worker . It shows your earnings
                                in USD balance only for you which you will be able to withdraw at
                                anytime in your bank accounts and different wallets in your local
                                currency or in USD almost instantly.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>Identity verification</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Revorium must follow a regulatory requirement commonly known as
                                'Know Your Customer (KYC), under which it is expected to verify the
                                identity of its customers. Account restrictions will be in place
                                while the verification process is in progress (mainly limiting the
                                ability to transfer money, request money, or use a card). To carry
                                out a quick identity check you must be at least 18 years old and we
                                need your name, address, a selfie, and a photo of your ID. We accept
                                any of the following:
                            </p>
                            <p>
                                <div>- National ID</div>
                                <div>- Passport</div>
                                <div>- Freelancer ID</div>
                                <div>- Full driving license</div>
                                <div>
                                    - If you are not a citizen of the country of your birthplace
                                    address, we will need to ask for proof of your right to reside
                                    in such country (visa along with national passport or a
                                    residence permit).
                                </div>
                                <div>
                                    - the UK, Switzerland, and the rest of EEA: National ID (valid
                                    for at least 1 month from the date of application), Passport (1
                                    month), Full driving license (1 month), all other documents such
                                    as visa or residence permit (3 months)
                                </div>
                                <div>- the US: 3 months for all documents</div>
                            </p>
                            <p>
                                We need a clear live photo (captured from your smartphone, i.e. not
                                a scan) of the provided document. Make sure that the document is
                                fully within the frame, without cropped corners or watermarks. If
                                you experience issues with the mobile app or phone, try reinstalling
                                the app or using another smartphone. The length of the process
                                depends on the accuracy and quality of the information you submit.
                                we'll keep you updated in case we may need more information from
                                you. Once the request is completed, you should no longer see the
                                request in your app. If anything's unclear, or you have a question
                                about ID verification, please raise a help request. You can only
                                verify your ID within the Revorium app and website, our support team
                                won't be able to accept ID pictures.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Where is the headquarters of Revorium?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Revorium is a Europen Fintech company based in Tallinn, Estonia
                                operates under Revorium OÜ. Principle branch is in Tallinn, Estonia.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What are the payment methods available to get paid with the payment
                                link?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Once you create a link to get paid, your clients or sender will see
                                different payment methods based on their location and the device
                                they are using to open the link.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>Clients can pay with</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                <div>- Card payment ( VISA & Mastercard )</div>
                                <div>- Paypal</div>
                                <div>- Apple pay</div>
                                <div>- Google pay</div>
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>How to create an invoice?</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                <div>- Tap to request money</div>
                                <div>
                                    - Fill in the form with the necessary info about your client or
                                    sender
                                </div>
                                <div>- Add a description of the services provided by you</div>
                                <div>- Add the amount you want to receive</div>
                                <div>- Customise your invoice by adding VAT if necessary</div>
                                <div>
                                    - Tap creates link; that’s it you have your invoice and link to
                                    get paid from anywhere in the world.
                                </div>
                                <div>
                                    - Share the link with your clients via email, Whatsapp, text
                                    messages, etc
                                </div>
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                How long does it take to receive the payment?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                You will be able to see the payment status in the Revorium
                                Dashboard. Until the client or sender pays the amount it will show
                                as pending, once the client has paid the amount it will show
                                processing the payment which means that client has made the payment
                                and it’s taking time to process the payment, payment processing time
                                could vary based on the client's location, payment methods. You need
                                to wait for 24 hours for the payment to be completed and once
                                completed, it will be added to your account for withdrawal, sending,
                                and spending.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                How do I dispute with clients?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                If in case the client has initiated a dispute then we as Revorium
                                look into the case of the dispute and If the reason is valid then we
                                will refund the amount to the client’s account within 15 to 30
                                working days through which he/she paid for your services from your
                                total earning unless the money is still in the settlement process.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                I want my money back. Can I cancel a payment?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                If you have used our link to pay your freelancers and in case of any
                                soft of any fraud issues then you should talk with our 24/7 live
                                support and we will take necessary action to stop processing the
                                payment.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                I forgot my password. How do I reset my password?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Go to the Revorium mobile app or web app and click forget password
                                and we will send you a new link in your email to reset your
                                password.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Why is my account on hold or Unavailable?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                It could be for different reasons and the major cause could be that
                                you haven’t provided us with enough information to verify your
                                details. In this case please check your notification and you will
                                find out why and what steps you need to take to regain access or
                                activate your account.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>Why is my payment pending?</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Payment is in pending status because the invoice hasn’t been paid by
                                your client yet. Once it has been paid you will get a notification
                                and also will be able to see that change in the status.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                How do I remove limitations from my Account?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Once you noticed that there are some limitations in your account;
                                first check your notification messages and there you will find the
                                reasons why there has been a limitation in your account and how to
                                remove it. If in case you do not get the notification regarding the
                                limitation on your account, please talk with our support team and we
                                will be helping you to solve the issues.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                How do I change the name on my Revorium Account?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                You can not change your name on your Revorium account once the
                                account has been created. It will come directly from your
                                ID/Passport that you have shared with us for account verification.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                How do I confirm my email address?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                We will send a verification link to your email and then you can
                                verify it by clicking the link.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What is the status of the Payment?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                You can always check it by logging in to your account and you will
                                see the payment status as pending, Settlement, and completed.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Why was my withdrawal declined?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>It could be declined for a couple of reasons;</p>
                            <p>
                                <div>
                                    - You do not have enough balance in your total earnings to
                                    withdraw the amount that you have initiated.
                                </div>
                                <div>- The account you added to withdraw, it’s not yours.</div>
                                <div>- Your account is not verified fully.</div>
                                <div>
                                    - Your account details are wrong. If none of them are the reason
                                    s then please talk with our support team.
                                </div>
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Why was my withdrawal declined?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                <b>Pending:</b> The client hasn’t paid the invoice yet. The invoice
                                is waiting to be paid by the client.
                            </p>
                            <p>
                                <b>Settlement:</b> The client has already paid the invoice and the
                                payment is under processing by the payment processing gateway, eg.
                                Mastercard, or Visa for settlement by checking different aspects of
                                the transaction like fraud, money laundering, etc.
                            </p>
                            <p>
                                <b>Complete:</b> The payment has been credited to your total earning
                                and it ready for withdraw at any time.
                            </p>
                            <p>
                                <b>Total Earnings: </b> Payment has been successfully settled by the
                                processing gateway and credited to the total earnings and is ready
                                to withdraw or send and spend.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    );
};

export default Faq;
